import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import WebMercatorViewport, { Bounds } from "viewport-mercator-project";

import { useGetOrCreateGuestPropertyLazyQuery } from "../../generated/graphql";
import { Footer, Header } from "./Layout";
import LayeredMap from "../Maps/LayeredMap";
import { Viewport } from "../Maps/utils/viewportHook";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "./utils";
import { setTitle } from "../../utils/title";
import { buildLink, getPath } from "common/routing";
import { getCurrentURL } from "../../utils/url";
import { AuthContext } from "../Authorization/AuthContext";
import { track } from "../../utils/tracking";
import { Search, SearchResultProps } from "../Search";

import {
  Navigation,
  SearchBox,
  SearchWrapper,
  Title,
  Explore,
  Shader,
  MapboxBackground,
  Loading,
  FooterWrapper,
  HeaderWrapper,
} from "./__styles__/Guest";

const Guestmap = () => {
  const history = useHistory();
  const location = useLocation();
  const account = useContext(AuthContext).account!;

  const [
    getOrCreateProperty,
    { loading: propertyLoading, data: propertyData },
  ] = useGetOrCreateGuestPropertyLazyQuery();

  const handleSearchResult = (searchResultData: SearchResultProps) => {
    track("Result clicked");

    void getOrCreateProperty({
      variables: {
        address: searchResultData.address,
        longitude: searchResultData.point.longitude,
        latitude: searchResultData.point.latitude,
        id: searchResultData.propertyId,
        parcelId: searchResultData.geocacheParcelId,
      },
    });
  };

  const mViewport = new WebMercatorViewport({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (!propertyLoading && propertyData) {
      if (propertyData.getProperty) {
        history.push({
          pathname: buildLink("residentProperty", {
            accountId: account.id,
            propertyId: propertyData.getProperty.id,
          }),
          state: {
            prevLocation: getCurrentURL(location),
          },
        });
      } else {
        history.push({
          pathname: buildLink("noProperty", {
            accountId: account.id,
          }),
          state: {
            prevLocation: getCurrentURL(location),
          },
        });
      }
    }
  }, [propertyData, propertyLoading]);

  const [viewport, setViewport] = useState<Viewport>({
    ...mViewport.fitBounds(account.bounds as Bounds),
  });

  const layers = {
    accountBoundary: {},
    parcels: {},
    firms: { firms: [] },
  };

  const trackExploreAction = () => {
    track("Map Expanded", {
      source: "search",
    });
  };

  if (propertyLoading) {
    return (
      <Loading>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </Loading>
    );
  }

  const title = account.publicPortal.homepageTitle;

  return (
    <>
      <LayeredMap
        height="100%"
        width="100%"
        baseMapStyle={account.defaultBaseMap.mapboxStyle}
        account={account}
        layers={layers}
        viewport={viewport}
        setViewport={setViewport}
        disableInteraction={true}
      >
        <Shader />
      </LayeredMap>
      <Navigation>
        <SearchWrapper>
          <Title>{title}</Title>
          <SearchBox>
            <Search handleResultClick={handleSearchResult} />
          </SearchBox>
          <Explore
            onClick={trackExploreAction}
            to={{
              pathname: getPath("explore"),
            }}
          >
            Explore Map
          </Explore>
        </SearchWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Navigation>
    </>
  );
};

export const Guest = () => {
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);
  const { account } = useContext(AuthContext);
  const title = account?.publicPortal.navBarTitle;

  setTitle(`${title} | ${accountTitle()}`);

  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <MapboxBackground>
        <Guestmap />
      </MapboxBackground>
    </>
  );
};

export default Guest;
