import React, { useContext } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { isNil, map } from "lodash";

import MapPreview from "../Maps/Preview";
import { LayerContextProvider } from "../Maps/layers";
import { isMedium, isSmall } from "../../utils/size";
import { useWindowSize } from "../../hooks/useWindowSize";
import { AuthContext } from "../Authorization/AuthContext";
import useNavigationTab from "../../hooks/useNavigationTabs";
import TabGroup from "../Common/TabGroup";
import {
  DisclaimerName,
  GetResidentPropertyInfoQuery,
  SubmissionIntakeSource,
  useGetResidentPropertyInfoQuery,
} from "../../generated/graphql";
import Improvements from "../AddressPanel/Improvements";
import Warnings from "../AddressPanel/Warnings";
import { track } from "../../utils/tracking";
import { setTitle } from "../../utils/title";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import Layout from "./Layout";
import { PrintButton } from "./PrintButton";
import { HomeButton } from "./HomeButton";
import UnavailablePage from "./UnavailablePage";
import DocumentUploadsList from "../AddressPanel/DocumentUploads/List";
import DocumentUploadsListItem from "../AddressPanel/DocumentUploads/ListItem";
import { RESOURCE_NAME } from "common/authorization";
import { HELPSCOUT_BEACON_TYPES, useHelpscoutBeacon } from "./utils";
import {
  defaultGuestDocumentsDisclaimer,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import { PropertyCarousel } from "../AddressPanel/PropertyOverview/Carousel";

import {
  AddressInfoContainer,
  CarouselContainer,
  ControlsContainer,
  DocumentListContainer,
  ImprovementsContainer,
  LoadingContainer,
  MapboxAttributionContainer,
  MapboxIconContainer,
  PropertyDetailsContainer,
  PropertyPageContainer,
  TabGroupContainer,
  WarningsContainer,
} from "./__styles__/PropertyPage";
import Disclaimer from "../Common/Disclaimer";
import { formatCoordinates } from "common/utils/coordinates";
import { AddressPanelContext } from "../AddressPanel/AddressPanelContext";
import { PropertyOverviewContextProvider } from "../AddressPanel/PropertyOverview/PropertyOverviewContext";
import { PropertyOverviewSections } from "../AddressPanel/PropertyOverview/PropertyOverviewSections";
import { getValueForParcelFieldNameOverride } from "common-client/utils/parcels";
import AccessDeniedPage, { ACCESS_DENIED_RESOURCE } from "./AccessDeniedPage";
import Divider from "../Common/Divider";
import { CreateSubmissionButton } from "../Common/CreateSubmissionButton";
import { spacing } from "../../stitches.config";
import { SUBMISSION_TYPE_MODULE } from "common/constants";

interface URLParams {
  accountId: string;
  propertyId: string;
}
interface Props extends RouteComponentProps<URLParams> {
  print: typeof window.print;
}

const tabNames = {
  OVERVIEW: "overview",
  SISD: "sisd",
  DOCUMENTS: "documents",
};

const buildTabs = (account: GetResidentPropertyInfoQuery["account"]) => {
  const OVERVIEW_TAB = { value: tabNames.OVERVIEW, label: "Overview" };
  const SISD_TAB = { value: tabNames.SISD, label: "SI/SD" };
  const CERTIFICATES_TAB = {
    value: tabNames.DOCUMENTS,
    label: "Files",
  };

  return account?.publicPortal.hideSISD
    ? [OVERVIEW_TAB, CERTIFICATES_TAB]
    : [OVERVIEW_TAB, SISD_TAB, CERTIFICATES_TAB];
};

const PropertyPage = ({ match, print = window.print }: Props) => {
  const { propertyId } = match.params;
  const { account, authorized } = useContext(AuthContext);

  const fetchPolicy = "network-only";
  const query = useLocation().search;
  const { data, loading, error } = useGetResidentPropertyInfoQuery({
    variables: { ...match.params, accountId: account!.id },
    fetchPolicy,
    onCompleted: data => {
      const urlParams = new URLSearchParams(query);
      const source = urlParams.get("src");

      track("PPP Viewed", {
        propertyId,
        source,
        streetAddress: data.property?.streetAddress,
      });
    },
  });

  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);

  const tabs = buildTabs(data?.account);
  const defaultTab = tabs[0];
  const [tab, setTab] = useNavigationTab({
    defaultTab: defaultTab!.value,
    allowedTabs: map(tabs, "value"),
  });

  const { width } = useWindowSize();

  if (loading) {
    return (
      <LoadingContainer>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </LoadingContainer>
    );
  }

  if (!data || !data.property) {
    return <AccessDeniedPage resource={ACCESS_DENIED_RESOURCE.PROPERTY} />;
  }

  if (error || !account) {
    return <UnavailablePage />;
  }

  let mapHeight = 400;
  if (isSmall() || isMedium()) {
    mapHeight = width * 0.52;
  }

  const {
    fullAddress,
    streetAddress,
    city,
    state,
    zipcode,
    latitude,
    longitude,
    documentUploads,
  } = data.property;

  const parcelId = data.property.parcel?.parcelId;
  const title = streetAddress ? streetAddress : "Missing Address";
  const formattedCoordinates = formatCoordinates({ latitude, longitude });

  const subTitle =
    isNil(city) || isNil(state) || isNil(zipcode)
      ? formattedCoordinates
      : `${city}, ${state} ${zipcode}`;

  setTitle(`${title} (${formattedCoordinates})`);

  const canEditWarnings = authorized({
    resource: RESOURCE_NAME.WARNING,
    permission: "update",
  });

  const disclaimerText = getDisclaimerText({
    disclaimerOverrides: account.disclaimerOverrides,
    name: DisclaimerName.DOCUMENTS,
    isPublic: true,
    defaultDisclaimers: {
      public: defaultGuestDocumentsDisclaimer({
        accountName: account.name,
        accountId: account.id,
        propertyId,
      }),
    },
  });

  const parcelIdLabel = getValueForParcelFieldNameOverride({
    overrides: account.parcelFieldNameOverrides,
    name: "parcelId",
  });

  return (
    <AddressPanelContext.Provider value={{ loading, property: data.property }}>
      <LayerContextProvider account={data.account!}>
        <Layout propertyId={propertyId}>
          <PropertyPageContainer>
            <ControlsContainer>
              <HomeButton />
              <PrintButton print={print} />
            </ControlsContainer>
            <MapPreview
              height={`${mapHeight}px`}
              width={"100%"}
              latitude={latitude!}
              longitude={longitude!}
              account={data.account!}
            />

            <MapboxAttributionContainer>
              <MapboxIconContainer>
                <Icon icon={Icons.MAPBOX_ATTRIBUTION} />
              </MapboxIconContainer>
              <span>© Mapbox, © OpenStreetMap</span>
            </MapboxAttributionContainer>
            <PropertyDetailsContainer data-testid="property-info-header">
              <AddressInfoContainer>
                <h1>{title}</h1>
                <h2>{subTitle}</h2>
                <h2 data-testid="parcel-id">{`${parcelIdLabel}: ${
                  parcelId ?? "-"
                }`}</h2>

                <div style={{ marginTop: spacing.m.value }}>
                  <CreateSubmissionButton
                    buttonText="New submission"
                    hideIfNoOptions={true}
                    disabled={false}
                    address={streetAddress}
                    latitude={latitude}
                    longitude={longitude}
                    propertyId={propertyId}
                    submissionTypeFilter={submissionType =>
                      submissionType.intakeSource ===
                        SubmissionIntakeSource.EXTERNAL &&
                      // Don't show EC Submissions since they are handled in another place
                      !submissionType.modules.includes(
                        SUBMISSION_TYPE_MODULE.EC_SUBMISSION
                      )
                    }
                  />
                </div>
              </AddressInfoContainer>
              <CarouselContainer>
                {latitude && longitude && (
                  <PropertyCarousel
                    latitude={latitude}
                    longitude={longitude}
                    address={fullAddress}
                    propertyId={propertyId}
                    size={"small"}
                  />
                )}
              </CarouselContainer>
            </PropertyDetailsContainer>

            <TabGroupContainer data-testid="tabs">
              <TabGroup currentTab={tab} setTab={setTab} tabs={tabs} />
            </TabGroupContainer>

            {tab === "overview" && (
              <>
                <WarningsContainer>
                  <Warnings
                    address={streetAddress}
                    propertyId={match.params.propertyId}
                    loading={false}
                    publicOnly={true}
                    canEditWarnings={canEditWarnings}
                  />
                </WarningsContainer>
                <Divider />
                <PropertyOverviewContextProvider
                  latitude={latitude}
                  longitude={longitude}
                >
                  <PropertyOverviewSections shouldShowRepLoss={false} />
                </PropertyOverviewContextProvider>
              </>
            )}

            {tab === "sisd" && (
              <ImprovementsContainer data-testid="improvements">
                <Improvements
                  address={streetAddress}
                  latitude={latitude}
                  longitude={longitude}
                  publicOnly={true}
                />
              </ImprovementsContainer>
            )}

            {tab === "documents" && (
              <DocumentListContainer data-testid="documents">
                <DocumentUploadsList property={data.property}>
                  {documentUploads.map(documentUpload => (
                    <div
                      key={documentUpload.id}
                      onClick={() => {
                        track("PPP View File Clicked", {
                          propertyId,
                          streetAddress,
                          documentUploadId: documentUpload.id,
                        });

                        return true;
                      }}
                    >
                      <DocumentUploadsListItem
                        documentUploadId={documentUpload.id}
                        isDeletable={false}
                        documentUploadMimeType={documentUpload.mimeType}
                        issuedAt={documentUpload.issuedAt}
                        // Because this is coming from the public site, we can assume the document is not hidden
                        hiddenFromPublic={false}
                        accountDocumentType={documentUpload.accountDocumentType}
                        filename={documentUpload.originalFilename}
                        createdAt={documentUpload.createdAt}
                      />
                    </div>
                  ))}
                </DocumentUploadsList>
                <Disclaimer message={disclaimerText} />
              </DocumentListContainer>
            )}
          </PropertyPageContainer>
        </Layout>
      </LayerContextProvider>
    </AddressPanelContext.Provider>
  );
};

export default PropertyPage;
