import React, { useContext } from "react";

import { setTitle } from "../../../utils/title";
import Layout from "../Layout";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "../utils";
import { PageSubheader } from "../PageSubheader";
import ActionButton, { Props as ActionButtonProps } from "./ActionButton";
import { AuthContext } from "../../Authorization/AuthContext";
import {
  defaultGuestCertificatesDisclaimer,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import { DisclaimerName } from "../../../generated/graphql";
import { MarkdownContainer } from "../../Common/__styles__/MarkdownContainer";

import {
  OptionsContainer,
  ButtonWrapper,
} from "../../DocumentUploads/ElevationCertificates/__styles__/CertificatesTable";
import { CertificatesContainer } from "./__styles__/ElevationCertificates";
import { PublicFilesTable } from "./FilesTable";

const GuestActionsColumn = ({
  original,
}: {
  original: ActionButtonProps["documentUpload"];
}) => {
  return (
    <OptionsContainer>
      <ButtonWrapper>
        <ActionButton documentUpload={original} />
      </ButtonWrapper>
    </OptionsContainer>
  );
};

const ElevationCertificatesPage = () => {
  const { account } = useContext(AuthContext);
  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.EC);

  setTitle(`Elevation Certificates | ${accountTitle()}`);

  const disclaimerText = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.CERTIFICATE_PAGE,
    isPublic: true,
    defaultDisclaimers: {
      public: defaultGuestCertificatesDisclaimer({
        accountName: account?.name,
      }),
    },
  });

  return (
    <Layout>
      <PageSubheader title="Elevation Certificates">
        <p>
          Elevation Certificates document your property's flood risk. They are
          used for a variety of purposes, including tracking ordinance
          compliance and informing flood insurance.
        </p>
        <MarkdownContainer markdown={disclaimerText} />
      </PageSubheader>
      <CertificatesContainer>
        <PublicFilesTable actionCell={GuestActionsColumn} />
      </CertificatesContainer>
    </Layout>
  );
};

export default ElevationCertificatesPage;
