import React from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

import { buildLink, getPath } from "common/routing";
import { useGetOrCreateGuestPropertyLazyQuery } from "../../generated/graphql";
import { setTitle } from "../../utils/title";
import FullPageMapContainer, {
  FullPageMapContainerProps,
} from "../Maps/FullPageMap";
import { Point } from "../Maps/LayeredMap";
import {
  accountTitle,
  HELPSCOUT_BEACON_TYPES,
  useHelpscoutBeacon,
} from "./utils";
import { Exit } from "./__styles__/Explore";
import { track } from "../../utils/tracking";
import { ParcelLayerConfig } from "../Maps/LayeredMap/parcelLayer";
import { PropertyLayerConfig } from "../Maps/LayeredMap/propertyLayer";
import { Button } from "../Common/Button";
import { Container } from "../Maps/__styles__/FullPageMap";

const ExitButton = ({ handleExitClick }: { handleExitClick: () => void }) => (
  <Exit>
    <Button
      onClick={handleExitClick}
      styleVariant="outlineLight"
      leftIconName="x"
      style={{
        width: "30px",
        height: "30px",
        minWidth: "unset",
        padding: "0px 5px 0px 5px",
      }}
    />
  </Exit>
);

export const canClickParcel: NonNullable<
  ParcelLayerConfig["interactive"]
>["click"] = parcels =>
  parcels.some(parcel => parcel.propertyIds?.length || parcel.address);

const useLatLong = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const longitude = parseFloat(params.lng as string);
  const latitude = parseFloat(params.lat as string);

  return {
    latitude,
    longitude,
  };
};

export const Explore = () => {
  const history = useHistory<{ prevLocation: string }>();
  const location = useLocation();

  const { latitude, longitude } = useLatLong();

  const [getOrCreateProperty] = useGetOrCreateGuestPropertyLazyQuery({
    onCompleted: propertyData => {
      if (propertyData.getProperty) {
        navigateToPPP(propertyData.getProperty.id, {
          latitude: propertyData.getProperty.latitude!,
          longitude: propertyData.getProperty.longitude!,
        });
      } else {
        history.push({
          pathname: buildLink("noProperty"),
        });
      }
    },
  });

  useHelpscoutBeacon(HELPSCOUT_BEACON_TYPES.OVERVIEW);

  const prevLocation =
    history.location.state?.prevLocation || getPath("guestHome");

  const handleExitClick = () => {
    history.push(prevLocation);
  };

  const navigateToPPP = (propertyId: string, point: Point) => {
    const params = queryString.parse(location.search);
    const firmId = params.firmId as string;

    const prevLocation = buildLink(
      "explore",
      {},
      {
        lat: point.latitude.toString(),
        lng: point.longitude.toString(),
        firmId,
      }
    );

    history.replace(prevLocation);

    history.push({
      pathname: buildLink("residentProperty", {
        propertyId: propertyId,
      }),
      state: {
        prevLocation,
      },
    });
  };

  const onParcelClick: ParcelLayerConfig["onClick"] = parcels => {
    track("Property clicked", { guest: true });
    // If parcel has one property, regardless of address, go to PPP
    const parcelWithOneProperty = parcels.find(
      parcel => parcel.propertyIds?.length === 1
    );
    if (parcelWithOneProperty) {
      navigateToPPP(
        parcelWithOneProperty.propertyIds![0]!,
        parcelWithOneProperty.point
      );
      return;
    }

    // If parcel has no address AND many properties, pick first and go to PPP
    const parcelWithNoAddressAndManyProperties = parcels.find(
      parcel => !parcel.address && parcel.propertyIds?.length
    );
    if (parcelWithNoAddressAndManyProperties) {
      navigateToPPP(
        parcelWithNoAddressAndManyProperties?.propertyIds![0]!,
        parcelWithNoAddressAndManyProperties.point
      );
      return;
    }

    // If parcel has address AND many properties, find (or create), then to PPP
    const parcelWithAddressAndManyProperties = parcels.find(
      parcel => parcel.address && parcel.propertyIds?.length
    );
    if (parcelWithAddressAndManyProperties) {
      void getOrCreateProperty({
        variables: {
          address: parcelWithAddressAndManyProperties.fullAddress,
          longitude: parcelWithAddressAndManyProperties.point.longitude,
          latitude: parcelWithAddressAndManyProperties.point.latitude,
        },
      });
      return;
    }

    // If parcel has address AND NO properties, (find or) create, then to PPP
    const parcelWithAddressAndNoProperty = parcels.find(
      parcel => parcel.address && !parcel.propertyIds?.length
    );
    if (parcelWithAddressAndNoProperty) {
      void getOrCreateProperty({
        variables: {
          address: parcelWithAddressAndNoProperty.fullAddress,
          longitude: parcelWithAddressAndNoProperty.point.longitude,
          latitude: parcelWithAddressAndNoProperty.point.latitude,
        },
      });
      return;
    }

    // If parcel has NO address AND NO properties, do nothing
  };

  const onSearchResult: FullPageMapContainerProps["onSearchResult"] = ({
    point,
    address,
    propertyId,
    geocacheParcelId,
  }) => {
    track("Property clicked", { guest: true, search: true });
    void getOrCreateProperty({
      variables: {
        address,
        latitude: point.latitude,
        longitude: point.longitude,
        id: propertyId,
        parcelId: geocacheParcelId,
      },
    });
  };

  const onPropertyClick: PropertyLayerConfig["onClick"] = property => {
    navigateToPPP(property.id, property.point);
  };

  setTitle(`Explore | ${accountTitle()}`);

  const point = latitude && longitude ? { latitude, longitude } : null;
  const markerConfig = { value: point };
  const parcelConfig = {
    interactive: { hover: true, click: canClickParcel },
    onClick: onParcelClick,
    value: { point },
  };
  const propertyConfig = {
    filterHidden: true,
    interactive: {
      hover: true,
      click: true,
    },
    onClick: onPropertyClick,
  };

  return (
    <Container>
      <FullPageMapContainer
        markerConfig={markerConfig}
        parcelConfig={parcelConfig}
        propertyConfig={propertyConfig}
        savedViewsConfig={{}}
        onSearchResult={onSearchResult}
      >
        <ExitButton handleExitClick={handleExitClick} />
      </FullPageMapContainer>
    </Container>
  );
};
